import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Earthworks brought an end to its status as a 'besloten venootschap' (BV) at the
end of 2018,  marking another stage in the transition to purely curiosity-driven
research. In 2019, the main  thrust of this will be directed towards the new
Gondwana map project, IGCP-628, and improved  models for Gondwana dispersal. `}</p>
    <p>{`Colin's independent project activity started in March 2001 when his appointment
at ITC became  less than full time, following the relocation of ITC's 'Delft'
activities to Enschede. In December  2002, independent office space was secured
on Lange Geer in Delft and his effects were relocated  there in January 2003,
after the Delft ITC building had been cleared. Colin retired fully from ITC in
December 2004. The present, larger, office space was acquired in August 2006
following the  commencement of the airborne survey project in Nigeria that was
to continue over many years. The BV was also set up at this time. `}</p>
    <p>{`The last of many ensuing projects - managing the interpretation of the new
airborne geophysical  coverage of Malawi - was completed early in 2018, bringing
an end to 12 years of commercial  consulting activity as a BV. `}</p>
    <p>{`The plaque, pictured below, is a gift from Colin's daughter Suzanna to mark this
event. Suzanna,  the one and only employee of Earthworks BV, worked the summer
of 2010 digitising global  geophysical data that is still in daily use, guiding
the interpretation of global tectonic processes. The business affairs of
Cambridge Paleomap Services Limited were also wound up during 2018. The CPSL
'Atlas' plate rotation software, with a pedigree stretching back more than 40
years,  nevertheless continues to perform perfectly, underpinning the on-going
research effort. `}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      